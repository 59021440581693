import * as React from 'react';
import { Form, Field } from 'react-final-form'
import './ContactUsForm.scss';
import localesApp from './locales/app';

export default class ContactUsForm extends React.PureComponent<> {
    handleSubmit = (values, {reset}) => {
        window.analytics.track('contact_us', values);
        window.$('#confirmation-modal').modal('show');
        reset();
    };

    renderConfirmModal() {
        return (
            <div
                id="confirmation-modal"
                className="modal fade"
                tabindex="-1" role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{localesApp.contactUs.submit}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {localesApp.contactUs.submitConfirm}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">{localesApp.ok}</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderContent({ handleSubmit, reset, pristine }) {
        return (
            <form
                className="p-5 bg-white"
                onSubmit={(event) => {
                    handleSubmit(event, reset)
                }}
            >

                <h2 className="h4 text-black mb-5">{localesApp.contactUs.contactUs}</h2>

                <div className="row form-group">
                    <div className="col-md-6 mb-3 mb-md-0">
                        <label className="text-black" htmlFor="name">{localesApp.contactUs.name}</label>
                        <Field
                            name="name"
                            component="input"
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6">
                        <label className="text-black" htmlFor="current_grade">{localesApp.contactUs.currentGrade}</label>
                        <Field
                            name="current_grade"
                            component="input"
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-md-6 mb-3 mb-md-0">
                        <label className="text-black" htmlFor="phone">{localesApp.contactUs.phone}</label>
                        <Field
                            name="phone"
                            component="input"
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="col-md-6">
                        <label className="text-black" htmlFor="email">{localesApp.contactUs.email}</label>
                        <Field
                            name="email"
                            component="input"
                            type="email"
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-md-12">
                        <Field
                            name="message"
                            render={({ input }) => (
                                <div>
                                    <label className="text-black" htmlFor="message">{localesApp.contactUs.message}</label>
                                    <textarea
                                        className="form-control"
                                        placeholder={localesApp.contactUs.messagePlaceHolder}
                                        {...input}
                                    />
                                </div>
                            )}
                        />
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-md-12 text-right">
                        <button
                            type="submit"
                            className="submit-btn btn"
                            disabled={pristine}
                        >
                            {localesApp.contactUs.submit}
                        </button>
                    </div>
                </div>
            </form>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.renderConfirmModal()}
                <Form
                    onSubmit={this.handleSubmit}
                    render={this.renderContent}
                />
            </React.Fragment>
        );
    }
}