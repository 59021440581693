import * as React from 'react';
import localesApp from './locales/app';
import {setLocalization, LOCALES} from './utils/locale';
import './App.scss';
import ContactUsForm from './ContactUsForm';

export default class App extends React.PureComponent<> {
    state = {
        locale: LOCALES.EN,
    };

    handleChangeLanguage = (lan) => {
        return () => {
            setLocalization(lan);
            this.setState({
                locale: lan,
            });
            this.forceUpdate();
        }
    };

    componentDidMount() {
        setLocalization(LOCALES.EN);
    }

    render() {
        return (
            <React.Fragment>
                <div className="site-wrap">
                    <div className="site-mobile-menu site-navbar-target">
                        <div className="site-mobile-menu-header">
                            <div className="site-mobile-menu-close mt-3">
                                <span className="icon-close2 js-menu-toggle"></span>
                            </div>
                        </div>
                        <div className="site-mobile-menu-body">
                            <ul className="site-nav-wrap">
                                <li>
                                    <a href="#home-section" className="nav-link">{localesApp.tab.home}</a>
                                </li>
                                <li>
                                    <a href="#about-section" className="nav-link">{localesApp.tab.aboutUs}</a>
                                </li>
                                <li>
                                    <a href="#team-section" className="nav-link">{localesApp.tab.team}</a>
                                </li>
                                <li>
                                    <a href="#services-section" className="nav-link">{localesApp.tab.services}</a>
                                </li>
                                <li>
                                    <a href="#testimonials-section" className="nav-link">{localesApp.tab.testimonials}</a>
                                </li>
                                <li>
                                    <a href="#blog-section" className="nav-link">{localesApp.tab.blog}</a>
                                </li>
                                <li>
                                    <a href="#contact-section" className="nav-link">{localesApp.tab.contact}</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <header className="site-navbar py-4 js-sticky-header site-navbar-target" role="banner">

                        <div className="container">
                            <div className="row align-items-center">

                                <div className="col-6 col-xl-2">
                                    <h1 className="mb-0 site-logo">
                                        <a href="index.html" className="h2 mb-0">
                                            All4ProEdu
                                        </a>
                                    </h1>
                                </div>

                                <div className="col-12 col-md-10 d-none d-xl-block">
                                    <nav className="site-navigation position-relative text-right" role="navigation">

                                        <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                                            <li>
                                                <a href="#home-section" className="nav-link">{localesApp.tab.home}</a>
                                            </li>
                                            <li>
                                                <a href="#about-section" className="nav-link">{localesApp.tab.aboutUs}</a>
                                            </li>
                                            <li>
                                                <a href="#team-section" className="nav-link">{localesApp.tab.team}</a>
                                            </li>
                                            <li>
                                                <a href="#services-section" className="nav-link">{localesApp.tab.services}</a>
                                            </li>
                                            <li>
                                                <a href="#testimonials-section" className="nav-link">{localesApp.tab.testimonials}</a>
                                            </li>
                                            <li>
                                                <a href="#blog-section" className="nav-link">{localesApp.tab.blog}</a>
                                            </li>
                                            <li>
                                                <a href="#contact-section" className="nav-link">{localesApp.tab.contact}</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>


                                <div className={`site-menu col-6 d-inline-block d-xl-none ml-md-0 py-3`}>
                                    <a href="#" className="site-menu-toggle js-menu-toggle text-black float-right">
                                        <span className="icon-menu h3"></span>
                                    </a>
                                </div>

                            </div>
                        </div>

                    </header>


                    <div className="hero site-blocks-cover overlay" data-aos="fade" id="home-section">
                        <div className="container">
                            <div className="row align-items-center justify-content-center">


                                <div className="col-md-6 mt-lg-5 text-center">
                                    <h1>{localesApp.mainHeader}</h1>
                                    <p className="mb-5">{localesApp.mainSubHeader}</p>
                                    <div>
                                        <button
                                            onClick={this.handleChangeLanguage(LOCALES.CH)}
                                            className="btn btn-primary mr-2 mb-2"
                                        >
                                            中文
                                        </button>
                                        <button
                                            onClick={this.handleChangeLanguage(LOCALES.EN)}
                                            className="btn btn-primary mr-2 mb-2"
                                        >
                                            English
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="site-section cta-big-image" id="about-section">
                        <div className="container">

                            <img src={require("./images/img_big_1.jpg")} className="about-bg-img img-fluid"/>
                            <div className="img-box">
                                <h2>{localesApp.aboutUs.header}</h2>
                                <p>{localesApp.aboutUs.body}</p>
                                <div className="text-center">
                                    <a
                                        href="#contact-section"
                                        className="about-us-cta btn btn-outline-dark smoothscroll"
                                    >
                                        {localesApp.aboutUs.getStarted}
                                    </a>
                                    <a
                                        href="#services-section"
                                        className="about-us-cta btn btn-outline-dark smoothscroll"
                                    >
                                        {localesApp.aboutUs.learnMore}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>


                    <section className="site-section border-bottom" id="team-section">
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-md-5 text-left">
                                    <h2 className="section-title mb-3">{localesApp.team.header}</h2>
                                </div>
                            </div>
                            <div className="row">
                                {
                                    localesApp.team.members.map((member, index) => {
                                        const {name, title, photo, details} = member;
                                        const anchor = `${photo}-${index}`;
                                        return (
                                            <div
                                                className="col-md-6 col-lg-4 mb-4"
                                                key={index}
                                            >
                                                <div>
                                                    <div
                                                        className="team-member"
                                                        data-toggle="modal"
                                                        data-target={`#${anchor}`}
                                                    >
                                                        <figure>
                                                            <img src={require(`./images/team/${photo}.jpg`)} className="team-member-photo img-fluid w-100"/>
                                                        </figure>
                                                        <div className="p-3">
                                                            <h3>{name}</h3>
                                                            <span className="position">{title}</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className="modal fade"
                                                    id={anchor}
                                                    tabindex="-1"
                                                    role="dialog"
                                                    aria-hidden="true"
                                                >
                                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLongTitle">{name}</h5>
                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                {details}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section>

                    <section className="site-section border-bottom bg-light" id="services-section">
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-12 text-center">
                                    <h2 className="section-title mb-3">{localesApp.services.header}</h2>
                                </div>
                            </div>
                            <div className="row align-items-stretch">
                                {
                                    localesApp.services.items.map((item) => {
                                        const {header, body, icon} = item;

                                        return (
                                            <div className="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up">
                                                <div className="unit-4 d-flex">
                                                    <div className="unit-4-icon mr-4">
                                                        <span className={`text-primary fas ${icon}`}></span>
                                                    </div>
                                                    <div>
                                                        <h3><strong>{header}</strong></h3>
                                                        <p>{body}</p>
                                                        <p>
                                                            <a
                                                                className="smoothscroll"
                                                                href="#contact-section"
                                                            >
                                                                {localesApp.services.learnMore}
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </section>

                    <section className="site-section testimonial-wrap text-center" id="testimonials-section">
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-12 text-center">
                                    <h2 className="section-title mb-3">{localesApp.testimonials.header}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="slide-one-item home-slider owl-carousel">
                            {
                                localesApp.testimonials.items.map((item) => {
                                    const {comments, name, offer} = item;

                                    return (
                                        <div>
                                            <div className="testimonial">

                                                <blockquote className="mb-5">
                                                    <p>&ldquo;{comments}&rdquo;</p>
                                                </blockquote>

                                                <figure className="mb-4 d-flex align-items-center justify-content-center flex-column">
                                                    <p>{name}</p>
                                                    <p>{offer}</p>
                                                </figure>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <img src={require("./images/school.png")} className="school"/>
                    </section>

                    <section className="site-section" id="blog-section">
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-12 text-center">
                                    <h2 className="section-title mb-3">{localesApp.blogs.header}</h2>
                                </div>
                            </div>

                            <div className="row">
                                {
                                    localesApp.blogs.items.map((item) => {
                                        const {token, summary} = item;

                                        return (
                                            <div className="col-md-6 col-lg-4 mb-4 mb-lg-4">
                                                <div className="h-entry">
                                                    <a
                                                        target="_blank" rel="noopener noreferrer"
                                                        href={`https://mp.weixin.qq.com/s/${token}`}
                                                    ><img src={require(`./images/blogs/${token}.jpg`)} className="img-fluid"/></a>
                                                    <p>{summary}</p>
                                                    <p>
                                                        <a
                                                            target="_blank" rel="noopener noreferrer"
                                                            href={`https://mp.weixin.qq.com/s/${token}`}
                                                        >
                                                        {localesApp.blogs.continue}
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </section>


                    <section className="site-section bg-light" id="contact-section">
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-12 text-center">
                                    <h2 className="section-title mb-3">{localesApp.contactUs.header}</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-7 mb-5">
                                    <ContactUsForm key={this.state.locale}/>
                                </div>
                                <div className="col-md-5">
                                    <div className="p-4 mb-3 bg-white">
                                        <p className="mb-0 font-weight-bold">{localesApp.contactUs.phone}</p>
                                        <p className="mb-4">
                                            <a href="tel:+14086498201">+1(408)649-8201</a>
                                        </p>

                                        <p className="mb-0 font-weight-bold">{localesApp.contactUs.email}</p>
                                        <p className="mb-4">
                                            <a href="mailto:kma@all4proedu.com">kma@all4proedu.com</a>
                                        </p>

                                        <p className="mb-0 font-weight-bold">{localesApp.contactUs.weChat}</p>
                                        <p className="mb-4">
                                            <a href="mailto:all4proedu01@gmail.com">maemae2014</a>
                                            <img src={require("./images/qr_code.jpg")} className="wechat-qr-code"/>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>


                    <footer className="site-footer">
                        <div className="container">
                            <div className="row pt-5 mt-5 text-center">
                                <div className="col-md-12">
                                    <div className="border-top pt-5">
                                        <p>
                                            Copyright &copy;
                                            <script>document.write(new Date().getFullYear());</script>
                                            All rights reserved
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </footer>
                </div>
            </React.Fragment>
        );
    };
}