import * as React from 'react';
import localized from '../utils/locale';

const locales = localized({
    en:{
        tab: {
          home: 'Home',
          aboutUs: 'About Us',
          team: 'Team',
          services: 'Services',
          testimonials: 'Testimonials',
          blog: 'Blog',
          contact: 'Contact'
        },
        mainHeader: 'We Are Innovative Leaders In Education',
        mainSubHeader: <React.Fragment>Alliance for Promising Education (All4ProEdu) are exceptional counselors in academic planning and college admission counseling. <br/>We are devoted to prepare students for their best-fit schools.</React.Fragment>,
        ok: 'Ok',
        aboutUs: {
            header: 'Pave Your Path to Your Dream School',
            body: 'College admission is a complex and competitive process. We are a group of passionate educators and professional counselors with the mission of sharing knowledge and revealing the transparency of college application with all students. Every student needs a counselor. We value the student’s individual difference and guide them along the journey.',
            getStarted: 'GET STARTED',
            learnMore: 'LEARN MORE',
        },
        team: {
            header: 'Who Makes Up All4ProEdu',
            members: [
                {
                    name: 'Kinsley Ma, MS',
                    title: 'Founder, Principal Counselor',
                    photo: 'kinsley_ma',
                    details: (
                        <p>
                            Kinsley received M.S. in Educational Psychology from Penn State University, her research involved the investigation of processes underlying students’ abilities to read and understand text and content, to critically examine and evaluate the information presented, and to make reasoned judgments as a result of reading. Kinsley is UC Berkeley Certified College Counselor and Career Planner, a member of Western Association for College Admission Counseling, and founder of Alliance for Promising Education (All4ProEdu). Ms. Kinsley has been practicing in the field since 2013 and she is extremely passionate about guiding both American and international students to get into their best-fit schools in order to follow their dreams.
                        </p>
                    ),
                },
                {
                    name: 'Bill Kwong, MS',
                    title: 'Principal Consultant',
                    photo: 'bill_kwong',
                    details: (
                        <p>
                            Bill received M.A. of East Asian Studies from Stanford University, and M.A. of Bilingual Education from New York University. He is also an alumni of Cornell University where he studied Human Development. Bill is the former director of Global Program at Crystal Spring Uplands School. Starting from 1983, Bill has been organizing and mentoring the American and Chinese students to exchange their culture and educational experience with focuses on creating transformative experiences for students and for life-long learners. In addition, he is sophisticated and resourceful in high-school counseling and application.
                        </p>
                    ),
                },
                {
                    name: 'Jessica Gerard, PhD',
                    title: 'Essay Specialist',
                    photo: 'jessica_gerard',
                    details: (
                        <p>
                            Dr. Gerard got her Ph.D. of Second Language Acquisition and Teaching and M.A. of English Language and Linguistics from University of Arizona. She was a lecturer at Stanford University, Columbia University, and served as both Director of ESL Writing Program and faculty advisor for the International Student Mentoring Program at Case Western Reserve University. Dr. Gerard has over 15 years of experience in coaching students with their essays in both college- and graduate school-level application. With her professional guidance, numerous students were admitted to top-tier and many selective universities.
                        </p>
                    ),
                },
                {
                    name: 'Sue Kong, MS',
                    title: 'Academic Management Supervisor',
                    photo: 'sue_kong',
                    details: (
                        <p>
                            Sue received M.A. of Intercultural Communication from the University of Pennsylvania. After her journey at Penn, Sue became an lecturer of Chinese at Penn State University and she also had lecturing experience at Princeton University, the Wharton School (Penn), Columbia University and University of Virginia. Sue is founder of Youthseed, a platform for tutoring. She is experienced in academic planning and management at high school, and she has been successfully guiding students to achieve their best potential.
                        </p>
                    ),
                },
                {
                    name: 'Mina Mi, MS',
                    title: 'Senior Counselor',
                    photo: 'mina_mi',
                    details: (
                        <p>
                            Mina received her bachelor's and master’s at Peking University in laws, and pursued her second Master in Politics at the University of Virginia Tech. She is also a UC Berkeley Certified College Counselor and Career Planner. She is a member of HECA, IECA and WACCA. She has 10+ years of experience in college planning, mentorship and application. She successfully helped the students to get into NYU, Boston College, George Washington University, Brandeis, UNC Chapel Hill, UIUC, UCSD, UC Santa Barbara, UC Irvine, UC Davis, University of Washington, Penn State, Purdue University, Fordham, Rochester Institute of Technology, etc.
                        </p>
                    ),
                },
                {
                    name: 'Henry Xu',
                    title: 'STEM/PBL Specialist',
                    photo: 'henry_xu',
                    details: (
                        <p>
                            Henry achieved his Bachelor of Science in Engineering at the University of Michigan. He was the lecture at UC Davis teaching the application of Mathematical models in automotive safety. He has rich experience in teaching in Science, Math and many college-level courses. Beyond that, he is also an exceptional and creative Project-based Science Learning Specialist who led the students to build on their own project by applying their knowledge into the real world application and problem-solving.
                        </p>
                    ),
                },
            ],
        },
        services: {
            header: 'Services',
            items: [
                {
                    header: 'Admissions Consulting',
                    body: (
                        <React.Fragment>
                            For everything related to middle, high school and college admissions, <strong>All4ProEdu</strong> have a team of expert counselors, passionate and experienced educators who are
                            always here to guide you through the process.
                        </React.Fragment>
                    ),
                    icon: 'fa-chalkboard-teacher',
                },
                {
                    header: 'Academic Management',
                    body: (
                        <React.Fragment>
                            We all know GPA is key to prove to the schools that you are a qualified candidate.
                            You may need help to select the courses that fit your academic interest and achieve good grades effectively and efficiently. <strong>All4ProEdu</strong> are a team of excellent instructors, private tutors to stand shoulder by shoulder with you.
                        </React.Fragment>
                    ),
                    icon: 'fa-user-graduate',
                },
                {
                    header: 'Learning Community',
                    body: (
                        <React.Fragment>
                            We strongly believe in the power of sharing. <strong>All4ProEdu</strong> are dedicated to build an
                            educational learning community that are available to all students, whoever and wherever you
                            are. Through our platform, one can not only get the view from professional insiders, but
                            also from your peers.
                        </React.Fragment>
                    ),
                    icon: 'fa-book-reader',
                }
            ],
            learnMore: 'Learn More',
        },
        testimonials: {
            header: 'Testimonials',
            items: [
                {
                    comments: 'Working with All4ProEdu has been immensely helpful and stress-reducing during times like the college application season. I was able to learn more countless schools and programs all through Ms. Kinsley. You can always count on her to be there to answer all your questions - she will go out of her way to find solutions through research to aid you in your college admissions process. I am grateful for her help and support and am certain that it will have a lasting positive impact on my future.',
                    name: 'G. D.',
                    offer: 'Admitted to Haas School of Business, UC Berkeley'
                },
                {
                    comments: 'All4ProEdu helped my daughter to find a list of suitable schools based on her characteristics, strengths, and personal interests. Ms. Kinsley is very professional where she was attentive to deeply understand my daughter, and make the student willing to open her heart. Ms. Kinsley mentored my daughter to find her uniqueness and taught her how to convey her experience and goal in an appealing method in her essay writing sessions.',
                    name: 'Mrs. L. Z.',
                    offer: <React.Fragment>Daughter admitted to UC Berkeley, UCLA, UC Davis, NYU, Emory, Chapman etc., <br/>several schools offered scholarship</React.Fragment>
                },
                {
                    comments: 'My standardization scores and GPA were moderately low, and I was a bit frustrated with applying to the ideal university. Since I have a slight learning difference, All4ProEdu customized the application strategy and recommended schools with systematic support for LD students. Through the brainstorming with Ms. Kinsley during my preparation for the essays, I found that I have so many flash points and talents that I have never noticed before.',
                    name: 'K. L.',
                    offer: <React.Fragment>Admitted to San Diego State University, CS Los Angeles, CS Pomona, etc., <br/>received scholarship of $32,000 per year from University of Redlands.</React.Fragment>
                }
            ],
        },
        blogs: {
            header: 'Our Blog Post',
            continue: 'Continue Reading...',
            items: [
                {
                    token: 'h9AL1nodcamnlZdRjnyIzA',
                    summary: 'The total number of University of California applications fell for the first time in 15 years. What is behind the data?',
                },
                {
                    token: 'oJk7uhtAT41wT3kA7T_mJg',
                    summary: 'The American college admissions scandal is just the right time. What can students and parents do in the face of injustice?',
                },
                {
                    token: '9tRyIhxLGnMaHdp9VFi-DA',
                    summary: 'Harvard University is accused of discriminating against Asians behind the triple question: Why does Harvard discriminate against Asians? Does Harvard really discriminate against Asians? Why do Asians feel discriminated against?',
                },
            ],
        },
        contactUs: {
            header: <React.Fragment>We Are Here to Help You Find the Best-fit Schools, <br/>and Gear You Up!</React.Fragment>,
            phone: 'Phone',
            email: 'Email',
            weChat: 'WeChat ID',
            name: 'Name',
            currentGrade: 'Current Grade',
            message: 'Message/Question',
            contactUs: 'Contact Us',
            submit: 'Submit',
            messagePlaceHolder: 'Write your notes or questions here...',
            submitConfirm: 'Thank you, we received your message. We will contact you as soon as possible.',
        },
    },
    ch: {
        tab: {
            home: '主页',
            aboutUs: '关于我们',
            team: '我们的团队',
            services: '我们的服务',
            testimonials: '用户反馈',
            blog: '博客文章',
            contact: '联系我们',
        },
        mainHeader: <React.Fragment>我们是教育领域的<br/>创新领导者</React.Fragment>,
        mainSubHeader: <React.Fragment>我们是学业规划，升学和申请咨询行业的杰出咨询师， <br/>我们致力于帮助学生进入到最适合自己的学校。</React.Fragment>,
        ok: '好的',
        aboutUs: {
            header: '铺平通往梦想学校之路',
            body: '申请大学是一个复杂而竞争激烈的过程。我们是一群充满激情的教育工作者和专业咨询师，我们的使命是与所有学生分享我们的知识和经验，从而增强大学申请的透明度。每个学生都需要一名咨询师。我们重视学生的个体差异，希望伴学生一路前行。',
            getStarted: '开始',
            learnMore: '更多',
        },
        team: {
            header: 'All4ProEdu核心团队',
            members: [
                {
                    name: 'Kinsley老师',
                    title: '创始人，首席咨询师',
                    photo: 'kinsley_ma',
                    details: (
                        <p>
                            Kinsley老师毕业于宾夕法尼亚州立大学， 获得教育心理学硕士学位。她着重研究学生潜在的阅读能力和理解文本内容的能力，辩证地测试和评估所呈现的信息，并通过阅读的结果做出合理的判断。 Kinsley老师是加州大学伯克利分校认证的大学申请咨询师和职业规划师，她也是西部大学申请咨询协会会员，以及Alliance for Promising Education（All4ProEdu）的创始人。 Kinsley老师自2013年以来在该领域从业，她致力于指导美国和国际学生进入他们最适合的学校，以实现他们的梦想。
                        </p>
                    ),
                },
                {
                    name: 'Bill老师',
                    title: '首席顾问',
                    photo: 'bill_kwong',
                    details: <p>Bill老师是斯坦福大学东亚研究和纽约大学双语教育的双硕士。他也是康奈尔大学的校友，人类发展专业本科。Bill老师是Crystal Spring Uplands School全球项目的前任主任。从1983年开始，Bill老师一直在组织和指导美国和中国学生进行文化和教育方面的交流，专注于为学生和终身学习者提供创造性的体验。此外，他在高中辅导和申请方面也有深厚的资源和经验。</p>
                },
                {
                    name: 'Gerard博士',
                    title: '论文导师',
                    photo: 'jessica_gerard',
                    details: <p>Gerard博士毕业于亚利桑那大学， 获得了第二语言习得与教学的博士、英语语言学硕士学位。她曾在斯坦福大学、哥伦比亚大学担任讲师，并在凯斯西储大学担任过ESL写作项目主任和国际学生辅导项目导师。Gerard博士拥有超过15年的经验，在大学和研究生阶段的申请中为学生提供论文辅导。在她的专业指导下，许多学生被多所顶级高校和竞争激烈的大学录取。</p>
                },
                {
                    name: 'Sue老师',
                    title: '学业管理导师',
                    photo: 'sue_kong',
                    details: <p>Sue老师毕业于宾夕法尼亚大学，获得了跨文化交流硕士学位。在宾夕法尼亚大学毕业后，Sue老师成为宾夕法尼亚州立大学的中文讲师，并在普林斯顿大学，沃顿商学院，哥伦比亚大学和弗吉尼亚大学担任过讲师。Sue老师是辅导平台——Youthseed的创始人。她在高中的学术规划和管理方面经验丰富，并成功指导学生开发他们的最大潜力。</p>
                },
                {
                    name: 'Mina老师',
                    title: '资深咨询师',
                    photo: 'mina_mi',
                    details: <p>Mina老师，北京大学法学学士、硕士，弗吉尼亚理工政治学硕士。加州伯克利大学认证的大学申请咨询师和职业规划师。全美高等教育顾问协会，全美独立教育顾问协会，美西大学升学咨询协会资深会员。具有十多年在美国大学从事本科生课业指导及授课，美国本土和国际学生大学升学规划，申请以及职业规划指导经验。在米娜老师的帮助下，学生顺利进入自己的的理想大学，比如纽约大学，波士顿学院，乔治华盛顿大学，加州大学，华盛顿大学，普渡大学，罗切斯特理工学院等。</p>
                },
                {
                    name: 'Henry老师',
                    title: 'STEM/项目科研学习导师',
                    photo: 'henry_xu',
                    details: <p>Henry老师本科毕业于密歇根大学工程专业。他曾经受邀到加州大学戴维斯分校进行授课，关于数学模型在汽车安全上的应用。Henry老师在高中数学，科学学科以及很多大学课程的教学上有着丰富的经验。除此之外，Henry老师还是一名出色的，有创意的项目科学学习的导师，带领和指导学生使用知识和解决问题的能力完成自己的科学和科研项目。</p>
                },
            ]
        },
        services: {
            header: '我们的服务',
            items: [
                {
                    header: '招生咨询',
                    body: (
                        <React.Fragment>
                            对于与中学，高中和大学入学申请相关的一切，<strong>All4ProEdu</strong>有着专业的顾问团队，负责和经验丰富的教育工作者，他们随时为您提供指导。
                        </React.Fragment>
                    ),
                    icon: 'fa-chalkboard-teacher',
                },
                {
                    header: '学业管理',
                    body: (
                        <React.Fragment>
                            我们知道GPA是向学校证明学生是合格候选人的关键。学生会需要专业的指导来选择符合自身兴趣的课程，学习如何有效和高效地取得好成绩。<strong>All4ProEdu</strong>有着优秀的导师团队，为学生量身定制学习计划，培养高效的学习习惯。
                        </React.Fragment>
                    ),
                    icon: 'fa-user-graduate',
                },
                {
                    header: '学习社区',
                    body: (
                        <React.Fragment>
                            我们坚信共享的力量。<strong>All4ProEdu</strong>致力于建立一个面向所有学生的教育学习社区，无论你是谁、身在何处。通过我们的平台，学生不仅可以向专业导师人员学习，也可以从其他的学员那里获得建议。
                        </React.Fragment>
                    ),
                    icon: 'fa-book-reader',
                }
            ],
            learnMore: '更多',
        },
        testimonials: {
            header: '用户反馈',
            items: [
                {
                    comments: 'All4ProEdu对我的大学申请提供了非常大的帮助，而且帮助我减轻了很多的压力。从Kinsley老师这里我了解到了更多适合我的学校和项目。你的任何问题在Kinsley老师这里都可以找到答案，而且她会不断研究去帮助你实现你的大学梦。我非常感激她的帮助和支持，而且她对我积极正面的帮助会持续影响我的未来。',
                    name: 'G. D.',
                    offer: '被加州伯克利Haas商学院录取'
                },
                {
                    comments: 'All4ProEdu帮助我的女儿根据她的特点，长处和个人兴趣找到合适的学校名单。Kinsley老师非常专业，她很注重深入了解我的女儿，能让学生愿意敞开心扉。Kinsley老师指导我的女儿找到她的独特性，并指导她如何在她的论文写作中以一种吸引人的方式传达她的经历和目标。',
                    name: 'Mrs. L. Z.',
                    offer: <React.Fragment>女儿被加州伯克利，加州洛杉矶，加州戴维斯，纽约大学，埃默里大学，查普曼大学等高校录取。<br/>并获得了其中几所学校颁发的奖学金。</React.Fragment>
                },
                {
                    comments: '我的标化成绩和GPA都中等偏下，对申请到理想的大学已经有些灰心了。因为我有轻微的学习差异，Kinsley老师根据我的具体情况为我定制了申请策略，并精心挑选了对LD的学生有系统支持的学校。在准备申请文书时，经过和Kinsley老师的头脑风暴，让我发掘到自己很多从前没有注意到的闪光点和才能，并在文书中一一展现。',
                    name: 'K. L.',
                    offer: <React.Fragment>被圣地亚哥州立大学，加州州立洛杉矶，加州州立波莫纳等学校录取，<br/>并获得了雷德兰兹大学颁发的每年3万2千美元的奖学金。</React.Fragment>
                }
            ],
        },
        blogs: {
            header: '我们的博客',
            continue: '阅读原文...',
            items: [
                {
                    token: 'h9AL1nodcamnlZdRjnyIzA',
                    summary: 'UC加州大学申请人数遭遇15年来首次下降，数据背后说明了什么？',
                },
                {
                    token: 'oJk7uhtAT41wT3kA7T_mJg',
                    summary: '美国大学录取丑闻这股风刮得正是时候，面对不公，学生和家长们能做些什么?',
                },
                {
                    token: '9tRyIhxLGnMaHdp9VFi-DA',
                    summary: '哈佛大学被指歧视亚裔现象背后三重问：哈佛大学凭什么歧视亚裔？哈佛大学真的歧视亚裔了吗？亚裔为什么觉得被歧视了？',
                },
            ],
        },
        contactUs: {
            header: <React.Fragment>我们在这里帮助您找到最合适的学校，<br/>并为您提供帮助！</React.Fragment>,
            phone: '电话',
            email: '邮箱',
            weChat: '微信',
            name: '姓名',
            currentGrade: '当前所在年级',
            message: '留言/问题',
            contactUs: '联系我们',
            submit: '提交',
            messagePlaceHolder: '如有任何问题欢迎留言',
            submitConfirm: '我们已收到你的留言，会尽快回复您的询问。感谢垂询！',
        },
    },
});

export default locales;

