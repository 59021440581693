export const LOCALES = {
    EN: 'en',
    CH: 'ch',
};

let localization = LOCALES.EN;

export const setLocalization = (locale) => {
    localization = locale;
};

const localized = (
    localizationObject,
) => {
    return new Proxy(localizationObject, {
        get: (localizationObject, key) => {
            return localizationObject[localization][key];
        },
    });
};

export default localized;